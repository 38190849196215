<template>
  <v-app v-if="serveTime" class="d-flex flex-column">
    <template v-if="showLayout">
      <AppHeaderHome v-if="$route.name == 'home'" :serviceUrl="serviceUrl"></AppHeaderHome>
      <AppHeader v-else :serviceUrl="serviceUrl"></AppHeader>
    </template>
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <AppPromise v-if="showLayout"></AppPromise>
    <AppFooter v-if="showLayout"></AppFooter>
    <SideCartBtn ref="cart" v-if="showLayout"></SideCartBtn>
    <MobileBottom v-if="showLayout" @cartClick="showCartSide()"></MobileBottom>
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppHeaderHome from "@/components/AppHeaderHome.vue";
import AppFooter from "@/components/AppFooter.vue";
import SideCartBtn from "@/components/SideCartBtn.vue";
import AppPromise from "@/components/AppPromise.vue";
import MobileBottom from "@/components/MobileBottom.vue";
import { openServices, getServiceLan } from "@/utils/services";

export default {
  name: "App",
  data () {
    return {
      serveTime: false,
      serviceUrl: ""
    }
  },
  computed: {
    showLayout () {
      return !this.$route.meta.seller;
    },
    token () {
      return this.$store.state.user.token;
    },
  },
  watch: {
    token (val) {
      if (val) {
        this.$store.dispatch("wishlist/requestWishlistList");
        this.$store.dispatch("cart/requestCartList");
        this.$store.dispatch("follow/requestFollowList");
        this.$store.dispatch("notice/requestConsult")
        this.$socketApi.initWebSocket((data) => this.receiverMsg(data));
      } else {
        this.$store.commit("wishlist/SET_WISH_LIST", []);
        this.$store.commit("cart/SET_CART_LIST", []);
        this.$store.commit("follow/SET_FOLLOW_LIST", []);
        this.$store.commit("notice/SET_UNREAD_COUNT", 0);
        this.$socketApi.closeWebSocket();
      }
    },
  },
  methods: {
    showCartSide () {
      this.$refs.cart.showCartDrawer = true;
    },
    async requestOnlineService () {
      const data = await this.$api.getOnlineService();
      if (data.state == "ok") {

        this.serviceUrl = data.msg[0]?.url;

        var obj = {}
        const arr = this.serviceUrl.split("?")[1]?.split("&")
        arr?.forEach(ele => {
          var key = ele.split("=")[0]
          var value = ele.split("=")[1]
          obj[`${key}`] = value
        });
        obj.language = getServiceLan()

        var newstr = "?"
        Object.keys(obj).forEach(key => {
          newstr += `${key}=${obj[`${key}`]}&`
        })
        this.serviceUrl = this.serviceUrl.split("?")[0] + newstr.slice(0, -1)

        for (var i = 0; i < data.msg.length; i++) {
          if (!data.msg[i].isExternalAccess) {
            openServices(data.msg[i].type, data.msg[i].url);
            break;
          }
        }
      }
    },
    receiverMsg (data) {
      if (this.token && data.type == "APP_MESSAGE") {
        this.$store.dispatch("notice/requestConsult")
      }
    }
  },
  components: {
    AppHeader,
    AppFooter,
    SideCartBtn,
    AppPromise,
    MobileBottom,
    AppHeaderHome,
  },
  async created () {

    await this.$store.dispatch("diff/requestServiceTime")
    this.serveTime = true

    const url = new URL(window.location.href.replace('#/', ''))
    const urlToken = url.searchParams.get('token')
    if (urlToken && urlToken != this.token) {
      const tokenStr = urlToken.split(".")[1]?.replace(/-/g, "+").replace(/_/g, "/")
      const userinfo = JSON.parse(decodeURIComponent(encodeURI(window.atob(tokenStr))));
      this.$store.commit('user/SET_USER_INFO', { token: urlToken, id: userinfo.uid });
      this.$store.dispatch("user/requestUserInfo")
    }

    if (this.token) {
      this.$store.dispatch("wishlist/requestWishlistList");
      this.$store.dispatch("cart/requestCartList");
      this.$store.dispatch("follow/requestFollowList");
      this.$store.dispatch("notice/requestConsult")
      setTimeout(() => this.$socketApi.initWebSocket((data) => this.receiverMsg(data))); // 延迟是为了websocket中$vm不为空
    }
    this.requestOnlineService();
    this.$store.dispatch("system/requestSystemCon");
  },
};
</script>
