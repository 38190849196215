<template>

  <div :class="boxClass">

    <!-- 骨架屏 -->
    <div v-if="!item.id">
      <v-skeleton-loader type="image" :height="skHeight"></v-skeleton-loader>
    </div>

    <!-- 只显示图片 -->
    <span v-else-if="this.type == 4">
      <router-link :to="`/product/${item.id}`" target="_blank" class="text-reset d-block lh-0">
        <img :src="item.iconUrl" alt="Shop" class="img-fluid w-100">
      </router-link>
    </span>

    <div v-else class="overflow-hidden" :class="{'rounded border': border}">
      <div class="row no-gutters align-center" :class="{'flex-nowrap': type == 3 || type == 2}">
        <div class="flex-shrink-0 col" :class="type == 1 ? 'col-12' : 'col-auto'">
          <div class="position-relative">
            <router-link :to="`/product/${item.id}`" target="_blank" class="text-reset d-block lh-0 text-center">
              <img :src="item.iconUrl" onerror="this.src='/uploads/all/logo_default.png';" :alt="item.name" class="img-fit" :class="type == 1 ? 'h-180px' : (type == 2 ? 'size-70px' : 'size-150px')">
            </router-link>
          </div>
        </div>
        <div class="minw-0 flex-shrink-0 col" :class="{'col-12': type == 1}">
          <div class="px-3 d-flex flex-column" :class="type == 1 || type == 3 ? 'py-2' : 'py-1'">
            <div :class="type == 1 || type == 3 ? 'fs-16 mb-2' : 'order-2 fs-14 lh-1'">
              <!-- <span class="grey--text fw-100 text-decoration-line-through">${{item.price.toFixed(2)}}</span> -->
              <span class="fw-700">${{ item.discountPrice?.toFixed(2) }}</span>
            </div>
            <div v-if="type == 1 || type == 3" class="fs-12 mb-2 opacity-60 ">{{$t('sale_num')}} {{item.sales}}</div>
            <h5 class=" fw-400 mb-2 lh-1-6" :class="type == 1 || type == 3 ? 'fs-13 text-truncate-2 h-40px' : 'text-truncate fs-12'">
              <router-link :to="`/product/${item.id}`" target="_blank" class="text-reset">{{item.name}}</router-link>
            </h5>
            <BuyWishbBtne v-if="type == 1 || type == 3" :btnClass="type == 1 ? 'flex-grow-1 me-1' : 'me-3'" :item="item"></BuyWishbBtne>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BuyWishbBtne from './buyWishbBtn.vue';
export default {
  components: {
    BuyWishbBtne
  },
  props: {
    type: {
      type: [String, Number],
      default: "1" // 1上下 2左右且不显示按钮 3左右 4仅图片
    },
    // 骨架屏高度
    skHeight: {
      type: [String, Number],
      default: 310
    },
    item: {
      type: [Object, Number, String],
      default: 0
      // default: () => {
      //   return {
      //     id: 18888,
      //     name: "ECONIKA Air Purifiers for Large Room - 4 in 1 Humidifier and Air Purifier in One - H13 True HEPA Air Purifier - Ionizer and UV-lamp - Covers Up to 700 Sq.Foot Home Air Cleaner",
      //     iconUrl:
      //       "https://m.media-amazon.com/images/I/415ARie2O-L._AC_UX679_.jpg",
      //     price: 225,
      //   }
      // }
    },
    border: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    boxClass () {
      switch (Number(this.type)) {
        case 1:
          return "product-box-one"
        case 2:
          return "product-box-two"
        case 3:
          return "product-box-three"
        default:
          return ""
      }
    },
  },
  methods: {

  },
  created () {
  }
}
</script>

<style>
</style>