<template>
  <header class="header-home-sticky">
    <div class="header-bg-wrap">
      <div class="topbar">
        <div class="container fs-13 py-0 px-0 px-md-3">
          <div class="row my-0 d-none d-md-flex align-center">
            <div class="py-2 col col-6">
              <div class="d-flex align-center">
                <SelectLangVue :border="false"></SelectLangVue>
                <div class="mx-4" vertical></div>
                <v-menu z-index="99999" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <span class="opacity-60">{{crtCurrency.text}}</span>
                      <i class="las la-angle-down ms-1 fs-12"></i>
                    </span>
                  </template>
                  <v-list class="fs-13">
                    <v-list-item v-for="(item, index) in currencyList" :key="index" @click="changeCurrency(item)">
                      <v-list-item-title class="fs-13 opacity-60">{{ item.text }} - {{ item.symbol }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div class="mx-4" vertical></div>
                <router-link to="/register-shop" class="text-reset opacity-60">{{$t("be_a_seller")}}</router-link>
              </div>
            </div>
            <div class="py-2 col col-6">
              <div class="d-flex align-center justify-end">
                <a target="_blank" class="text-reset opacity-60" :href="serviceUrl">
                  <span>{{$t('help_line_link')}}</span>
                </a>
                <div class="mx-4" vertical></div>
                <router-link to="/user/wishlist" class="text-reset opacity-60">
                  <span>{{$t('wishlist')}} ({{$store.state.wishlist.length}})</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- <v-divider></v-divider> -->
      </div>

      <div class="logobar">
        <div class="container pb-md-0 pt-4">
          <div class="d-flex align-center">
            <div class="logo">
              <router-link to="/" class="d-block lh-0 ">
                <img src="/uploads/all/logo_default.png" alt="Shop" height="60" />
              </router-link>
            </div>
            <div class="spacer"></div>
            <div class="flex-grow-1 search-box" :class="{'open' : showSearch}">
              <v-form class="border rounded flex-grow-1" @submit.native.prevent>
                <div class="row row--dense align-center">
                  <div class="d-md-none col col-auto ms-1">
                    <v-btn @click="toggleShowSearch()" icon dark><i class="las la-arrow-left fs-18 ts-05"></i></v-btn>
                  </div>
                  <div class="position-relative h-100 col col-auto ms-1">
                    <div>
                      <div @click="showSelectMakk = true" class="c-pointer fs-14"> {{crtSearchType.name}} <i class="la la-caret-square-o-down fs-14 ts-05"></i></div>
                    </div>
                    <div v-show="showSelectMakk" class="search-select position-absolute shadow-xs">
                      <div v-for="(item, index) in searchTypes" :key="index" @click="changeSearchType(item)" class="pt-2 pb-2 c-pointer">{{item.name}}</div>
                    </div>
                  </div>
                  <div class="col">
                    <v-text-field v-model="searchText" @keyup.enter.native="toSearch()" :placeholder="$t('search')" hide-details solo single-line flat dark background-color="transparent"></v-text-field>
                  </div>
                  <div class="d-none d-md-block col col-auto me-1">
                    <v-btn @click="toSearch()" elevation="0" color="primary" block :disabled="!searchText.trim()" dark> {{$t('search')}} </v-btn>
                  </div>
                </div>
              </v-form>
            </div>
            <v-btn @click="toggleShowSearch()" class="d-md-none border-gray-300" fab outlined rounded small dark>
              <i class="las la-search fs-18 ts-05"></i>
            </v-btn>
            <div class="spacer d-none d-md-block"></div>
            <div class="d-none d-md-block">
              <div class="d-flex align-center">
                <template v-if="!userToken">
                  <v-badge class="me-3" :content="0" :value="0" color="red" overlap>
                    <i class="las la-user fs-30 lh-1 opacity-70"></i>
                  </v-badge>
                  <router-link class="text-reset opacity-80 fw-500" to="/user/login">{{$t('login')}}</router-link>
                  <span class="mx-3 opacity-60">{{$t('or')}}</span>
                  <router-link class="text-reset opacity-80 fw-500" to="/user/registration">{{$t('registration')}}</router-link>
                </template>
                <template v-else>
                  <router-link class="text-reset" to="/user/product-query">
                    <v-badge class="me-3" :content="unread" :value="unread" color="red" overlap>
                      <i class="las la-user fs-30 lh-1 opacity-70"></i>
                    </v-badge>
                  </router-link>
                  <router-link class="text-reset opacity-80 fw-500" to="/user/dashboard">{{$t('dashboard')}}</router-link>
                  <span class="mx-3 opacity-60">{{$t('or')}}</span>
                  <div class="text-reset opacity-80 fw-500 c-pointer" to="/user/registration" @click="logOut()">{{$t('logout')}}</div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-show="showSelectMakk" @click="showSelectMakk = false" class="select-makk"></div>
      </div>

      <div class="d-none d-md-block">
        <div class="container py-0">
          <div class="d-flex align-center justify-center py-2">
            <v-list class="d-flex py-0" color="transparent" dark>
              <v-list-item class="flex-grow-0 flex-fill" v-for="(item, index) in navList" :key="index">
                <v-list-item-title>
                  <span>
                    <router-link :to="item.path" class="text-reset fs-13 fw-700 opacity-80">
                      {{ item.name }}
                    </router-link>
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
          <!-- <v-divider></v-divider> -->
        </div>
      </div>

      <div class="container">
        <div class="fs-20 mt-16">{{$t('learn_about',{ appName })}}</div>
        <div class="mt-4 fw-600 b2b-title">{{$t('the_leading_B2B_ecommerce')}}</div>
        <div class="col-lg-7 mt-5 my-autocomp" style="padding:0;">
          <v-text-field @keyup.enter.native="toSearchPro()" v-model="searchProText" height="54" outlined background-color="#fff" filled rounded :placeholder="defaultSearchPro">
            <template slot="append">
              <v-btn @click="toSearchPro()" rounded color="#ff6a00" text-color="#fff" dark>
                <v-icon left style="transform: rotateY(180deg);">las la-search</v-icon>{{$t('search')}}
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <div class="d-flex mt-3 mb-16" style="flex-wrap: wrap;">
          <div class="mr-6 mt-1" style="flex-shrink: 0;">{{$t('frequently_searched')}}: </div>
          <router-link v-for="(item, index) in searchList" :key="index" :to="`/search/${item}`">
            <v-btn class="mr-4 mb-2" outlined rounded color="#fff" small>{{item}}</v-btn>
          </router-link>
        </div>
      </div>
    </div>

    <div class="nav-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="nav-cude">
              <div class="icon-container">
                <img src="https://s.alicdn.com/@img/imgextra/i1/O1CN01tbfptg1Fv1tsyww7q_!!6000000000548-2-tps-96-96.png">
              </div>
              <div class="fs-24 mt-1 mb-8">{{$t('millions_of_business_offerings')}}</div>
              <div class="mb-8">{{$t('explore_products')}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="nav-cude">
              <div class="icon-container">
                <img src="https://s.alicdn.com/@img/imgextra/i2/O1CN01VxEwc91YXeNmcyV6j_!!6000000003069-2-tps-96-96.png">
              </div>
              <div class="fs-24 mt-1 mb-8">{{$t('assured_quality_and_transactions')}}</div>
              <div class="mb-8">{{$t('ensure_production_quality_from')}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="nav-cude">
              <div class="icon-container">
                <img src="https://s.alicdn.com/@img/imgextra/i2/O1CN01WxanpW1Hv9ESW9cfs_!!6000000000819-2-tps-96-96.png">
              </div>
              <div class="fs-24 mt-1 mb-8">{{$t('one_stop_trading_solution')}}</div>
              <div class="mb-8">{{ $t('order_seamlessly_from') }}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="nav-cude">
              <div class="icon-container">
                <img src="https://s.alicdn.com/@img/imgextra/i4/O1CN010nrLfB25RaSKdVtHu_!!6000000007523-2-tps-96-96.png">
              </div>
              <div class="fs-24 mt-1 mb-8">{{$t('tailored_trading_experience')}}</div>
              <div class="mb-8">{{$t('get_curated_benefits')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import SelectLangVue from '@/components/SelectLang.vue';

export default {
  data () {
    return {
      showSearch: false,
      crtSearchType: null,
      searchTypes: [
        { name: this.$t('seller_product'), path: "/search" },
        { name: this.$t('shops'), path: "/all-shops" }
      ],
      showSelectMakk: false,
      searchText: "",
      navList: [
        {
          path: "/",
          name: this.$t('home')
        },
        {
          path: "/shop",
          name: this.$t('seller_product_all')
        },
        {
          path: "/all-categories",
          name: this.$t('all_categories')
        },
        {
          path: "/all-brands",
          name: this.$t('all_brands')
        },
        {
          path: "/register-shop",
          name: this.$t('be_a_seller')
        },
      ],
      currencyList: [
        {
          text: "U.S. Dollar",
          symbol: "$"
        }
      ],
      crtCurrency: {
        text: "U.S. Dollar",
        symbol: "$"
      },
      searchProText: "",
      defaultSearchPro: "iphone 15 pro max"
    };
  },
  props: ["serviceUrl"],
  computed: {
    userToken: (vm) => vm.$store.state.user.token,
    unread: (vm) => vm.userToken ? vm.$store.state.notice.unread : 0,
    appName: () => process.env.VUE_APP_NAME,
    searchList: (vm) => vm.$store.state.search,
  },
  components: {
    SelectLangVue
  },
  methods: {
    toggleShowSearch () {
      this.showSearch = !this.showSearch
      if (this.showSelectMakk) this.showSelectMakk = false
    },
    changeSearchType (item) {
      this.crtSearchType = item
      this.showSelectMakk = false
    },
    toSearch () {
      if (!this.searchText.trim()) return
      if (this.crtSearchType.path == "/search") {
        this.$router.push(`${this.crtSearchType.path}/${this.searchText}`).then(() => {
          this.$store.commit("search/ADD_SEARCH_LIST", this.searchText.trim())
        })
      } else {
        this.$router.push({ path: `${this.crtSearchType.path}`, query: { shopName: this.searchText } })
      }
    },
    toSearchPro () {
      const txt = !this.searchProText?.trim() ? this.defaultSearchPro : this.searchProText
      this.$router.push(`/search/${txt}`).then(() => {
        this.$store.commit("search/ADD_SEARCH_LIST", txt.trim())
      })
    },
    changeCurrency (ele) {
      this.crtCurrencys = ele;
    },
    logOut () {
      this.$store.commit("user/REMOVE_USER_INFO")
      if (this.$route.name != "home") {
        this.$router.replace({ path: "/" })
      }
    },
  },
  async created () {
    this.crtSearchType = this.searchTypes[0]
  },
};
</script>

<style lang="less" scoped>
.header-home-sticky {
  z-index: 1;
  color: #fff;
  .header-bg-wrap {
    background: url("/public/uploads/all/home_bg.jpg") no-repeat 60%;
    min-height: 624px;
    background-color: #111110;
    background-size: cover;
  }
  .topbar {
    position: relative;
    z-index: 2;
    /* background-color: #fff; */
  }

  .logobar {
    min-height: 68px;
    position: relative;
    z-index: 1;
  }
  .search-select {
    padding: 0 13px;
    z-index: 999;
    left: 0;
    min-width: 80px;
    text-align: center;
    font-size: 12px;
    background-color: #fff;
    color: #000;
  }
  .select-makk {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .nav-wrapper {
    color: #fff;
    background-color: #3a190b;
    padding: 60px 0;
    .nav-cude {
      background-color: hsla(0, 0%, 100%, 0.03);
      border-radius: 16px;
      padding: 20px 10px;
      height: 100%;
      @media (min-width: 960px) {
        & {
          padding: 40px 20px;
        }
      }
      cursor: pointer;
      .icon-container {
        background-color: hsla(0, 0%, 100%, 0.08);
        border-radius: 30px;
        height: 60px;
        padding: 10px;
        width: 60px;
        img {
          width: 40px;
          height: auto;
          max-width: 100%;
        }
      }
      &:hover {
        background-color: rgba(255, 102, 0, 0.12);
      }
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
.b2b-title {
  font-size: 1.875rem;
}

@media (max-width: 959px) {
  .search-box {
    position: absolute;
    width: calc(100% - 24px);
    padding: 9px 0;
    height: 100%;
    left: 12px;
    right: 12px;
    top: -100%;
    /* background: #fff; */
    background: #1e1e1e;
    display: flex;
    align-items: center;
    z-index: 3;
    transition: top 0.3s;
    -webkit-transition: top 0.3s;
  }
  .search-box.open {
    top: 0;
  }
}
@media (min-width: 960px) {
  .search-box {
    position: static;
  }
  .b2b-title {
    font-size: 2.75rem;
  }
}
</style>

<style lang="less">
.header-home-sticky {
  .my-autocomp {
    .v-input__append-inner {
      margin-top: 6px !important;
    }
  }
}
</style>