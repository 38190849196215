import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=f8917816"
import script from "./AppFooter.vue?vue&type=script&lang=ts"
export * from "./AppFooter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports