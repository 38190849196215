import Vue from "vue";
import VueI18n from "vue-i18n";
import $storage from '@/storage'

//element 国际化处理
// import locale from "element-ui/lib/locale";
// import elEn from "element-ui/lib/locale/lang/en";
// import elCn from "element-ui/lib/locale/lang/zh-CN";

Vue.use(VueI18n);

function loadLocaleMessages () {
    //检测locales文件夹中有多少个语系
    const locales = require.context(
        "@/locales",
        true,
        /.json$/
    );
    const messages = {};
    locales.keys().forEach(file => {
        const keyArr = file.split('/');
        keyArr.shift();
        messages[keyArr.join('.').replace(/\.json$/g, '')] = locales(file);
    });
    return {
        // cn: { ...messages.cn, ...elCn },
        // en: { ...messages.en, ...elEn },
        en_US: messages.en_US,
        zh_CN: messages.zh_CN,
        ja_JP: messages.ja_JP,
        ko_KR: messages.ko_KR,
        id_ID: messages.id_ID,
        hi_IN: messages.hi_IN,
        pt_PT: messages.pt_PT,
        es_ES: messages.es_ES,
        vi_VN: messages.vi_VN,
        th_TH: messages.th_TH,
        fr_FR: messages.fr_FR,
        it_IT: messages.it_IT,
        ms_MY: messages.ms_MY,
        ru_RU: messages.ru_RU,
        zh_TW: messages.zh_TW,
    };
}
const i18n = new VueI18n({
    locale: $storage.getSelectLang,
    messages: loadLocaleMessages()
});
// locale.i18n((key, value) => i18n.t(key, value));
Vue.prototype.$t = (key, value) => i18n.t(key, value)

export default i18n;
