import Vue from 'vue'
import VueRouter from 'vue-router'
import LeadView from '@/views/lead.vue'
import HomeView from '@/views/home.vue'
import $store from '@/store'


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/lead',
    name: "lead",
    component: LeadView,
    meta: {
      seller: true
    }
  },
  {
    path: '/',
    name: "home",
    component: HomeView
  },
  {
    path: '/all-brands',
    component: () => import('@/views/all-brands.vue')
  },
  {
    path: '/all-categories',
    component: () => import('@/views/all-categories.vue')
  },
  {
    path: '/brand/:brandName',
    component: () => import('@/views/brand.vue')
  },
  {
    path: '/category/:id',
    component: () => import('@/views/category.vue')
  },
  {
    path: '/privacy-policy',
    component: () => import('@/views/privacy-policy.vue')
  },
  {
    path: '/product/:id',
    component: () => import('@/views/product.vue')
  },
  {
    path: '/register-shop',
    component: () => import('@/views/register-shop.vue'),
    meta: {
      seller: true
    }
  },
  {
    path: '/shop-information',
    component: () => import('@/views/shop-information.vue'),
    meta: {
      seller: true
    }
  },
  {
    path: '/shop-register-end',
    component: () => import('@/views/shop-register-end.vue'),
    meta: {
      seller: true
    }
  },
  {
    path: '/return-policy',
    component: () => import('@/views/return-policy.vue')
  },
  {
    path: '/search/:searchText',
    component: () => import('@/views/search.vue')
  },
  {
    path: '/all-shops',
    component: () => import('@/views/all-shops.vue')
  },
  {
    path: '/seller-policy',
    component: () => import('@/views/seller-policy.vue')
  },
  {
    path: '/shop',
    component: () => import('@/views/shop.vue')
  },
  {
    path: '/shop/:storeId',
    component: () => import('@/views/shop2.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/views/store/SomeProduct.vue'),
      },
      {
        path: '/shop/:storeId/products',
        component: () => import('@/views/store/AllProduct.vue'),
      },
    ]
  },
  {
    path: '/terms',
    component: () => import('@/views/terms.vue')
  },
  {
    path: '/track-order',
    component: () => import('@/views/track-order.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/checkout',
    component: () => import('@/views/checkout.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/order-confirmed/:serialNo',
    component: () => import('@/views/order-confirmed.vue'),
    meta: {
      requireAuth: true
    }
  },

  {
    path: '/user/login',
    name: 'login',
    component: () => import('@/views/user/login.vue')
  },
  {
    path: '/user/registration',
    component: () => import('@/views/user/registration.vue')
  },
  {
    path: '/user/create-refund-request/:orderNo',
    component: () => import('@/views/user/create-refund-request.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/forgot-password',
    component: () => import('@/views/user/forgot-password.vue')
  },
  {
    path: '/user/dashboard',
    component: () => import('@/views/user/dashboard.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/product-query',
    component: () => import('@/views/user/product-query.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/product-query/:id',
    component: () => import('@/views/user/product-query2.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/profile',
    component: () => import('@/views/user/profile.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/purchase-history',
    component: () => import('@/views/user/purchase-history.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/purchase-history/:serialNo',
    component: () => import('@/views/user/purchase-history2.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/refund-requests',
    component: () => import('@/views/user/refund-requests.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/wallet',
    component: () => import('@/views/user/wallet.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/wishlist',
    component: () => import('@/views/user/wishlist.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/follow',
    component: () => import('@/views/user/follow.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user/update-account/:accountType',
    component: () => import('@/views/user/update-account.vue'),
    meta: {
      requireAuth: true
    }
  },
  // 404页面一定要放最后
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // },
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if ($store.state.user.token) {
      next()
    } else {
      next({ path: '/user/login' })
    }
  } else {
    next()
  }
})


export default router
