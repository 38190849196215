<template>
  <div>
    <Banner :list1="adList[0]" :list2="adList[1]" :list3="adList[2]" :list4="adList[3]"></Banner>

    <SwiperContainer :title="$t('popular_categories')" :subTitle="$t('view_all')" subLink="/all-categories" :dataList="categoryList" slidesView="8" autoplay containerClass="container py-0 pe-0 pe-md-3 ps-3">
      <CategoriesWidget slot-scope="{ item }" :item="item" skHeight="186"></CategoriesWidget>
    </SwiperContainer>

    <template v-for="(ele, index) in dataList">
      <SwiperContainer :key="index" :title="ele.list ? ele.title : ''" :dataList="ele.list ?? 8" :slidesView="ele.slidesView" :isRow2="ele.isRow2" containerClass="container py-0">
        <StoreBox v-if="ele.isStore" slot-scope="{ item }" :item="item" :type="ele.storeType"></StoreBox>
        <ProductBox v-else slot-scope="{ item }" :item="item" :type="ele.productType" :skHeight="ele.skHeight"></ProductBox>
      </SwiperContainer>

      <!-- 注册商家 -->
      <SwiperContainer v-if="ele.isRegisterShop && ele.list" :key="`ad${index}`" :dataList="1" :slidesView="1" isAd>
        <div>
          <span>
            <router-link to="/register-shop" class="text-reset d-block lh-0">
              <img src="/uploads/all/GC0RU0SB2Ae0WslF9rp2GrNBcUsT87taVKBT7IKT.jpg" alt="Shop" class="img-fluid w-100" />
            </router-link>
          </span>
        </div>
      </SwiperContainer>

      <SwiperContainer v-else-if="ele.adMsg" :key="`ad${index}`" :dataList="ele.adMsg.products" :slidesView="ele.adMsg.slidesView" :isRow2="false" isAd>
        <ProductBox slot-scope="{ item }" :item="item" :type="4"></ProductBox>
      </SwiperContainer>
    </template>

    <div class="py-8 grey lighten-4 mt-8">
      <div class="container">
        <div></div>
        <div class="mt-8 border-top pt-8 border-gray-300">
          <h6 class="fs-16 fw-700 text-uppercase opacity-70 mb-2">
            {{$t('categories')}}
          </h6>
          <div class="row row--dense">
            <div v-for="(item, index) in categoryList" :key="index" class="lh-1 mb-2 col-sm-6 col-md-4 col-xl-3 col-12">
              <router-link :to="`/category/${item.id}`" class="text-reset d-inline-block fs-11 text-uppercase opacity-70 fw-700">{{item.name}}</router-link>
              <div>
                <router-link v-for="(subItem, subIndex) in item.list" :key="subIndex" :to="`/category/${item.id}-${subItem.id}`" class="text-reset d-inline-block fs-11 opacity-70">
                  {{subItem.name}}<span v-if="subIndex != item.list.length - 1" class="px-1">|</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesWidget from "@/components/widget/Categories";
import ProductBox from "@/components/widget/ProductBox";
import StoreBox from "@/components/widget/StoreBox.vue";
import SwiperContainer from "@/components/SwiperContainer";
import Banner from "@/components/home/Banner";
export default {
  data () {
    return {
      // 推荐产品(无分类)
      // 推荐店铺(手机和配件类1)
      // 健康、美容与美发产品(健康、美容与美发类9)
      // 家电及工具店推荐店(家用电器类13)
      // 数字商店推荐(电子产品类3)
      // 女装店推荐(女士服饰类5)
      // 美容店(健康、美容与美发类9)
      // 时尚产品展示(珠宝和手表类8)
      dataList: [
        {
          title: this.$t("best_sellers_"),
          categoryPid: 4,
          isStore: false,
          list: null,
          isRegisterShop: true,
        },
        {
          title: this.$t("recommended_store"),
          categoryPid: 1,
          isStore: true,
          slidesView: 3,
          storeType: 3,
          list: null,
          adPosition: 5,
        },
        {
          title: this.$t("anti_epidemic"),
          categoryPid: 15,
          isStore: false,
          slidesView: 3,
          productType: 3,
          skHeight: 186,
          isRow2: true,
          list: null,
          adPosition: 6,
        },
        {
          title: this.$t("make_home_better"),
          categoryPid: 13,
          isStore: true,
          slidesView: 5,
          storeType: 2,
          list: null,
          adPosition: 7,
        },
        {
          title: this.$t("electronics_store"),
          categoryPid: 3,
          isStore: true,
          slidesView: 2,
          storeType: 4,
          list: null,
        },
        {
          title: null,
          categoryPid: 3,
          isStore: false,
          slidesView: 6,
          list: null,
          adPosition: 8,
        },
        {
          title: this.$t("focus_on_beauty"),
          categoryPid: 5,
          isStore: true,
          slidesView: 3,
          storeType: 3,
          list: null,
        },
        {
          title: null,
          categoryPid: 5,
          isStore: false,
          slidesView: 4,
          productType: 3,
          isRow2: true,
          list: null,
        },
        {
          title: this.$t("beauty_hot_shop"),
          categoryPid: 9,
          isStore: true,
          slidesView: 5,
          storeType: 2,
          list: null,
          adPosition: 9,
        },
        {
          title: this.$t("fashion_hot"),
          categoryPid: 8,
          isStore: false,
          list: null,
          adPosition: 10,
        },
        {
          title: null,
          categoryId: 150,
          slidesView: 3,
          productType: 3,
          isRow2: true,
          isStore: false,
          list: null,
        },
      ],
    };
  },
  computed: {
    adList: (vm) => vm.$store.state.advertise,
    categoryList: (vm) =>
      vm.$store.state.category.length ? vm.$store.state.category : 8,
  },
  components: {
    CategoriesWidget,
    ProductBox,
    StoreBox,
    SwiperContainer,
    Banner,
  },
  methods: {
    async init () {
      await this.$store.dispatch("advertise/requestAllAdList");

      this.dataList.forEach(async (ele) => {
        var data;
        if (ele.isStore) {
          data = await this.$api.homeSeller(ele.categoryPid, 5, true);
        } else {
          const params = {
            pageNumber: 1,
            pageSize: 10,
            sortBy: 0,
            // brandName: this.brandName ?? this.$refs.brandDom?.selectList.map(e => e.name),
            categoryPid: ele.categoryPid,
            categoryId: ele.categoryId,
            // minPrice: this.$refs.priceDom?.minPrice,
            // maxPrice: this.$refs.priceDom?.maxPrice,
            // keyWord: this.searchText,
            // sellerId: this.sellerId
          };
          data = await this.$api.search(params);
        }
        if (data.state != "ok") return;

        if (ele.isStore) {
          ele.list = data.msg;
        } else {
          ele.list = data.msg.list;
        }
        if (ele.adPosition) {
          ele.adMsg = this.adList.find((e) => ele.adPosition == e.position);
        }
      });
    },
    async requestAd () {
      await this.$store.dispatch("advertise/requestAllAdList");
      this.dataList.forEach((ele, index) => {
        ele.adMsg = this.adList[index + 3];
      });
    },
  },
  created () {
    this.init();
    this.$store.dispatch("category/requestCategoryList");
  },
};
</script>

<style scoped>
@import url("@/assets/3f4f1c04.css");
</style>

<style scoped>
h2 {
  font-size: 16px;
}

@media (min-width: 960px) {
  h2 {
    font-size: 24px;
  }
}
</style>
